import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@mantine/core'
import styles from './PageHeader.module.scss'
import Button from '../common/Button'
import { ChevronLeft } from 'react-feather'
import { Link } from 'gatsby'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid'

const PageHeader = ({ title, date, author, featuredImage, topic }) => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.heading}>
            <Button
              href="/resources"
              variant="outline"
              leftIcon={<ArrowLeftIcon width={16} />}
            >
              All articles
            </Button>
            {/* <div className={styles.breadcrumbs}>
              <a href="/resources/">Blog</a>
              <ArrowRightIcon width={16} /> <a>{topic}</a>
            </div> */}
            <h1>{title}</h1>
            <p className={styles.authorName}>{author.name}</p>
            <p className={styles.authorRole}>{author.role}</p>
          </div>
          <div className={styles.imageContainer}>
            <img src={featuredImage} alt={title} />
          </div>
          {/* <div className={styles.leftContainer}>
            <Link className={styles.backButton} to={`/resources`}>
              <ChevronLeft />
              All resources
            </Link>
            <div className={styles.heading}>
              <p>{date}</p>
              <h1>{title}</h1>
              <p className={styles.authorName}>{author.name}</p>
              <p className={styles.authorRole}>{author.role}</p>
            </div>
          </div> */}
          {/* <div className={styles.imageContainer}>
            <img src={featuredImage} alt={title} />
          </div> */}
        </div>
      </div>
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
