import React from 'react'
import styles from './CTA.module.scss'
import Button from './Button'
import { signUpClick } from '../../utils/tracker'

const CTA = ({ text }) => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h2>{text}</h2>

          <div className={styles.buttonContainer}>
            <div className={styles.buttonInnerContainer}>
              <Button
                href="https://app.atlas.co/login"
                size="xl"
                onClick={signUpClick}
                className={'plausible-event-name=Signup'}
              >
                Sign up -{' '}
                <span
                  style={{
                    fontWeight: 300,
                    fontSize: '0.75em',
                    marginTop: '-1rem'
                  }}
                >
                  it's free
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CTA
